import { Grid, Row, Col } from '@product/react-styled-flexboxgrid'
import Carousel from 'components/Carousel'
import MostViewedCarouselItem from 'components/Home/MostViewed/MostViewedCarouselItem'
import MostViewedImageCarouselItem from 'components/Home/MostViewed/MostViewedImageCarouselItem'
import CustomPropTypes from 'lib/custom-prop-types'
import map from 'lodash/map'
import ArrowLogo from 'public/assets/svg/carousel-arrow.svg'
import EyeLogo from 'public/assets/svg/viewed.svg'
import { useState } from 'react'
import styled from 'styled-components'
import { remCalc, color, fontFamily, respondTo } from 'styles/mixins'

const LogoContainer = styled.span`
  display: flex;
  ${respondTo.smAndDown`
    width: ${remCalc(64)};
    margin-left: ${remCalc(6)};
    svg {
      transform: scale(0.5);
    }
  `}

  ${respondTo.mdAndUp`
    margin-right: ${remCalc(20)};
  `}  
`

const MostViewedTitle = styled.div`
  display:flex;
  align-items: center;
  position: absolute;
  font-family: ${fontFamily.gothic};
  color: ${color.white};
  font-weight: bold;
  text-transform: uppercase;

  ${respondTo.smAndDown`
    font-size: ${remCalc(32)};
    z-index: 10;
    margin: ${remCalc(42, 24)} 0 0;
    width: 100%;
  `}

  ${respondTo.mdAndUp`
    top: ${remCalc(107)};
    font-size: ${remCalc(64)};
    line-height: ${remCalc(70)};
    z-index: 10;
    margin-left: ${remCalc(20)};

    svg {
      color: ${color.sunshineyellow};
    }
  `}
`

const ImageCarousel = styled(Carousel)`
  transform: translateY(-255px);

  .slick-slide {
    transition:
      opacity 300ms ease,
      transform 300ms ease;
    transform: scale(0.9);
    transform-origin: bottom;
    position: relative;
    pointer-events: auto;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      height: ${remCalc(8)};
      width: ${remCalc(8)};
      background: ${color.white};
      border-radius: 50%;
      left: 50%;
      opacity: 0;
      transition: opacity 300ms ease;
      transform: translateX(-4px);
    }
  }

  .slick-current {
    transform: translateY(-3px);
    transform: scale(1);
    &:before {
      opacity: 1;
    }
  }

  .slick-list {
    padding-left: ${remCalc(16)};
  }

  ${respondTo.mdAndUp`
    transform: translateY(-490px);
    pointer-events: none;

    .slick-slide {
      &:before {
        top: ${remCalc(4)};
        height: ${remCalc(12)};
        width: ${remCalc(12)};
        background: ${color.white};
        transform: translateX(-6px);
        transform: scale(0.9);
      }
    }

    .slick-list {
      left: 50%;
      transform: translateX(-8.4%);
      padding: 0;
    }

    .slick-current {
      transform: none;
    }
  `}
`

const ContentCarousel = styled(Carousel)`
  .slick-list {
    height: ${remCalc(800)};
    overflow: hidden;
    will-change: transform;
  }

  .slick-slide {
    height: ${remCalc(800)};
    will-change: opacity, visibility;
    &:not(.slick-active) {
      pointer-events: none;
    }
  }

  ${respondTo.smAndDown`
    .slick-slide,
    .slick-list {
      height: ${remCalc(620)};
    }
  `}
`

const ArrowWrapper = styled.button`
  pointer-events: auto;
  top: ${remCalc(-310)};
  transform: none;
  z-index: 1;
  height: ${remCalc(800)};
  width: calc(50% - 650px);

  ${respondTo.md`
    width: calc(50% - 500px);
  `}

  &:before {
    content: none;
  }
`

const LeftArrow = styled(ArrowWrapper)`
  left: 0;
  cursor: url('/yp/assets/svg/arrow-cursor-left.svg'), auto;

  &.slick-disabled {
    cursor: none;
  }
`

const RightArrow = styled(ArrowWrapper)`
  right: 0;
  cursor: url('/yp/assets/svg/arrow-cursor-right.svg'), auto;

  &.slick-disabled {
    cursor: none;
  }
`

const ArrowLeft = (props) => {
  return <LeftArrow {...props}>Previous Item</LeftArrow>
}

const ArrowRight = (props) => {
  return <RightArrow {...props}>Next Item</RightArrow>
}

const ContentArrowWrapper = styled.button`
  position: absolute;
  background: transparent;
  height: ${remCalc(69)};
  width: ${remCalc(92)};
  margin: ${remCalc(0, 10)};
  pointer-events: auto;
  bottom: auto;
  top: ${remCalc(212)};
  z-index: 5;
  transition: opacity 300ms ease;

  &:before {
    content: none;
  }

  &.slick-disabled {
    opacity: 0.5;
  }

  svg {
    stroke: ${color.white};
    fill: ${color.white};

    path {
      fill: ${color.white};
    }
  }

  ${respondTo.smAndDown`
    height: ${remCalc(43)};
    width: ${remCalc(56)};
    top: ${remCalc(295)};
  `}
`

const ContentLeftArrow = styled(ContentArrowWrapper)`
  transform: translateX(443px);
  left: 50%;

  svg {
    transform: rotate(180deg);
  }
  ${respondTo.mdAndDown`
    transform: translateX(250px);
  `}

  ${respondTo.smAndDown`
    left: ${remCalc(219)};
    transform: none;
  `}

  ${respondTo.xsAndDown`
    left: auto;
    right: ${remCalc(79)};
  `}
`

const ContentRightArrow = styled(ContentArrowWrapper)`
  transform: translateX(545px);
  left: 50%;
  right: auto;

  ${respondTo.mdAndDown`
    transform: translateX(356px);
  `}

  ${respondTo.smAndDown`
    left: ${remCalc(283)};
    transform: none;
  `}

  ${respondTo.xsAndDown`
    right: ${remCalc(12)};
    left: auto;
  `}
`

const StyledCol = styled(Col)`
  position: relative;
`

const ContentArrowLeft = (props) => {
  return (
    <ContentLeftArrow {...props}>
      <ArrowLogo />
    </ContentLeftArrow>
  )
}

const ContentArrowRight = (props) => {
  return (
    <ContentRightArrow {...props}>
      <ArrowLogo />
    </ContentRightArrow>
  )
}

const indexOfHalfToneImageToPreGenerate = 3

const MostViewedCarousel = ({ className, entityUuids }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [slider3, setSlider3] = useState(null)
  const [slider4, setSlider4] = useState(null)
  const MostViewedCarouselSettingsImage = {
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    ref: (slider) => setSlider4(slider),
    asNavFor: slider3,
    centerMode: false,
    fade: false,
    focusOnSelect: true,
    lazyLoad: false,
  }

  const MostViewedCarouselSettingsContent = {
    infinite: false,
    speed: 300,
    asNavFor: slider4,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
    ref: (slider) => setSlider3(slider),
    prevArrow: <ContentArrowLeft />,
    nextArrow: <ContentArrowRight />,
    centerMode: false,
    fade: true,
    lazyLoad: false,
    afterChange: (index) => {
      setSlideIndex(index)
    },
  }

  const MostViewedCarouselItems = map(entityUuids, (entityUuid, index) => {
    return (
      <MostViewedCarouselItem
        key={`most-liked-carousel-items-${index}`}
        entityUuid={entityUuid}
        showBackground={index >= slideIndex - 1 && index <= slideIndex + 1}
        initBackground={slideIndex + indexOfHalfToneImageToPreGenerate > index}
      />
    )
  })

  const MostViewedImageCarouselItems = map(entityUuids, (entityUuid, index) => {
    return (
      <MostViewedImageCarouselItem
        entityUuid={entityUuid}
        key={`most-liked-image-carousel-items-${index}`}
      />
    )
  })

  return (
    <>
      <Grid>
        <Row>
          <StyledCol xs={16} md={16} lg={14} xsOffset={0} smOffset={0} mdOffset={0} lgOffset={1}>
            <MostViewedTitle>
              <LogoContainer>
                <EyeLogo />
              </LogoContainer>
              Most Viewed
            </MostViewedTitle>
          </StyledCol>
        </Row>
      </Grid>
      {MostViewedCarouselItems.length > 1 && (
        <ContentCarousel className="content-carousel" config={MostViewedCarouselSettingsContent}>
          {MostViewedCarouselItems}
        </ContentCarousel>
      )}
      {MostViewedImageCarouselItems.length > 1 && (
        <ImageCarousel className={className} config={MostViewedCarouselSettingsImage}>
          {MostViewedImageCarouselItems}
        </ImageCarousel>
      )}
    </>
  )
}

MostViewedCarousel.propTypes = {
  entityUuids: CustomPropTypes.uuidArray,
}

export default MostViewedCarousel
