import LazyLoad from 'components/Common/LazyLoad'
import ArticleLink from 'components/CustomLink/ArticleLink'
import CustomDate from 'components/Fields/CustomDate'
import useFetchArticles from 'hooks/hook-fetch-articles'
import { useGa4Params } from 'hooks/hook-ga4-params'
import CustomPropTypes from 'lib/custom-prop-types'
import { setVariables } from 'lib/gtm'
import { portrait } from 'lib/image-placeholder'
import get from 'lodash/get'
import pick from 'lodash/pick'
import styled from 'styled-components'
import { remCalc, fontFamily, color, respondTo } from 'styles/mixins'

const Wrapper = styled.div`
  margin: ${remCalc(30, 8, 30)};
  box-shadow: 0 ${remCalc(10, 32)} 0 ${color['black-20']};
  border-radius: ${remCalc(20)};
  overflow: hidden;
  display: block;
  position: relative;

  img {
    height: ${remCalc(401)};
    width: ${remCalc(240)};
  }

  .slick-current & {
    border: ${remCalc(2)} solid ${color.white};
    margin-top: ${remCalc(30)};

    img {
      height: ${remCalc(401)};
      width: ${remCalc(240)};
      opacity: 0.97;
    }
  }

  ${respondTo.smAndDown`
    margin: ${remCalc(17, 8, 30)};
    border-radius: ${remCalc(10)};
    box-shadow: 0 ${remCalc(5, 17)} 0 ${color['black-20']};

    img {
      width: ${remCalc(118)};
      height: ${remCalc(188)};
      opacity: 0.8;
    }

    .slick-current & {
      img {
        width: ${remCalc(118)};
        height: ${remCalc(188)};
        opacity: 1;
      }
    }
  `}
`

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${color['black-15']};

  .slick-current & {
    opacity: 0.75;
  }
`

const ContentWrapper = styled.div`
  padding: ${remCalc(0, 18, 26, 12)};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: ${color.white};

  ${respondTo.smAndDown`
    display: none;
  `}
`

const Title = styled.h2`
  font-size: ${remCalc(20)};
  line-height: ${remCalc(24)};
  color: ${color.white};
  font-family: ${fontFamily.gothic};
  font-weight: bold;
  text-shadow: 0 ${remCalc(2, 11)} 0 ${color['black-50']};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`

const StyledCustomDate = styled(CustomDate)`
  font-size: ${remCalc(12)};
  font-weight: bold;
  letter-spacing: ${remCalc(0.4)};
  line-height: ${remCalc(15)};
  text-shadow: 0 ${remCalc(2, 3)} 0 ${color['black-50']};
  margin-top: ${remCalc(13)};
`

const StyledA = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  color: transparent;

  .slick-current & {
    display: block;
  }
`

const MostViewedImageCarouselItem = ({ className, entityUuid }) => {
  const { pageType } = useGa4Params()
  const articles = useFetchArticles(entityUuid)
  const article = get(articles, `processedNode[${entityUuid}]`, {})

  const { headline = '', socialHeadline = '', images, publishedDate, entityId, urlAlias } = article

  const { portrait: portraitImage } = get(images, '[0]', {})

  const routeHandler = () => {
    // ga4 13.1.2
    setVariables({
      event: 'GA4_tracking',
      category: 'recirculation',
      subcategory: 'widget',
      action: 'click',
      customized_parameters: {
        widget_name: 'most_liked',
        article_id: entityId,
        destination_url: urlAlias,
        page_type: pageType,
      },
    })
  }

  return (
    <>
      <Wrapper className={className}>
        <LazyLoad placeholder={<img src={portrait} />}>
          <meta itemProp="url" content={get(portraitImage, 'url')} />
          <meta itemProp="width" content={get(portraitImage, 'width')} />
          <meta itemProp="height" content={get(portraitImage, 'height')} />
          <img src={get(portraitImage, 'url')} width="192" height="304" />
        </LazyLoad>
        <ImageOverlay />
        <ContentWrapper>
          <Title>{socialHeadline || headline}</Title>
          <StyledCustomDate date={publishedDate} withTime={false}></StyledCustomDate>
        </ContentWrapper>
        <ArticleLink
          article={pick(article, ['entityUuid', 'urlAlias'])}
          routeHandler={routeHandler}
        >
          <StyledA>Read Article</StyledA>
        </ArticleLink>
      </Wrapper>
    </>
  )
}

MostViewedImageCarouselItem.propTypes = {
  entityUuid: CustomPropTypes.uuid,
}

export default MostViewedImageCarouselItem
