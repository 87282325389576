import MostViewedCarousel from 'components/Home/MostViewed/MostViewedCarousel'
import ParallaxGroup from 'components/Parallax/ParallaxGroup'
import CustomPropTypes from 'lib/custom-prop-types'
import styled, { ThemeProvider, css } from 'styled-components'
import { remCalc, respondTo, color } from 'styles/mixins'

const ParallaxMostViewed = styled(ParallaxGroup)`
  height: ${remCalc(620)};
  z-index: 1;
  background-image: linear-gradient(
    180deg,
    ${color.avocado} 13%,
    ${color.sunshiesyellowmuddy} 100%
  );

  ${(props) =>
    props.theme.isReport &&
    css`
      background-image: linear-gradient(180deg, ${color.teal} 13%, ${color.greengrape} 100%);
    `}

  ${(props) =>
    props.theme.isLearn &&
    css`
      background-image: linear-gradient(
        180deg,
        ${color.bluehouse} 13%,
        ${color.lightlavender} 100%
      );
    `}

    ${(props) =>
    props.theme.isSpark &&
    css`
      background-image: linear-gradient(180deg, ${color.fuchsiaBlue} 13%, ${color.heliotrope} 100%);
    `}

  ${respondTo.mdAndUp`
    height: ${remCalc(800)};
    background-image: linear-gradient(90deg, ${color.avocado} 26%, ${
      color.sunshiesyellowmuddy
    } 100%);

    ${(props) =>
      props.theme.isReport &&
      css`
        background-image: linear-gradient(90deg, ${color.teal} 26%, ${color.greengrape} 100%);
      `}

    ${(props) =>
      props.theme.isLearn &&
      css`
        background-image: linear-gradient(
          90deg,
          ${color.bluehouse} 26%,
          ${color.lightlavender} 100%
        );
      `}
    ${(props) =>
      props.theme.isSpark &&
      css`
        background-image: linear-gradient(
          90deg,
          ${color.fuchsiaBlue} 26%,
          ${color.heliotrope} 100%
        );
      `}
  `}
`

const MostViewed = ({ theme = {}, entityUuids = [] }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <ParallaxMostViewed>
          <MostViewedCarousel entityUuids={entityUuids} />
        </ParallaxMostViewed>
      </ThemeProvider>
    </>
  )
}

MostViewed.propTypes = {
  entityUuids: CustomPropTypes.uuidArray,
}

export default MostViewed
