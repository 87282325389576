import { Grid, Row, Col } from '@product/react-styled-flexboxgrid'
import ArticleLevelLabel from 'components/ArticleLevel/ArticleLevelLabel'
import ArticleLink from 'components/CustomLink/ArticleLink'
import CustomDate from 'components/Fields/CustomDate'
import Link from 'components/Link'
import useFetchArticles from 'hooks/hook-fetch-articles'
import { useGa4Params } from 'hooks/hook-ga4-params'
import CustomPropTypes from 'lib/custom-prop-types'
import { setVariables } from 'lib/gtm'
import get from 'lodash/get'
import pick from 'lodash/pick'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { remCalc, color, fontFamily, respondTo } from 'styles/mixins'

const StyledCustomDate = styled(CustomDate)`
  display: block;
  color: #FFF;
  font-family: ${fontFamily.roboto};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.5px;
  margin-block-end: 24px;
`

const Wrapper = styled.div`
  height: ${remCalc(800)};
  width: 100vw;
  position: relative;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  a.section-link {
    color: ${color.sunshineyellow};
  }
`

const ContentWrapper = styled.div`
  display: block;
  z-index: 111;
  position: relative;
  width: ${remCalc(374)};
  margin-top: ${remCalc(238)};


  ${respondTo.smAndDown`
    max-width: ${remCalc(320)};
    margin: ${remCalc(112, 0, 0)};
  `}

  ${respondTo.mdAndDown`
    width: ${remCalc(345)};
    margin: ${remCalc(112, 24, 0)};
  `}  

  ${respondTo.mdAndUp`
    width: ${remCalc(345)};
    margin: ${remCalc(238, 24, 0)};
  `}

  ${respondTo.xs`
    margin: ${remCalc(112, 24, 0)};
  `}
`

const Title = styled.h2`
  font-size: ${remCalc(28)};
  line-height: ${remCalc(32)};
  margin: ${remCalc(8, 0, 8)};
  font-family: ${fontFamily.gothic};
  color: ${color.white};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  a {
    text-decoration: none;
    color: ${color.white};
  }

  ${respondTo.mdAndUp`
    font-size: ${remCalc(38)};
    line-height: ${remCalc(44)};
    margin: ${remCalc(24, 0, 16)};
    -webkit-line-clamp: 5;
  `}
`

const AuthorFooter = styled.span`
  display: block;
  align-items: center;
  color: ${color.white};
  text-shadow: 0 ${remCalc(2, 4)} ${color['black-20']};
  margin-bottom: ${remCalc(38)};
  font-weight: 500;
  font-size: ${remCalc(14)};
  line-height: ${remCalc(16)};

  a {
    color: ${color.white};
  }

  img {
    border-radius: 50%;
    block-size: ${remCalc(40)};
    inline-size: ${remCalc(40)};
    display: inline-block;
    margin-right: ${remCalc(2)};
    vertical-align: middle;
    margin-right: 12px;
  }

  ${respondTo.smAndDown`
    font-size: ${remCalc(12)};
    line-height: ${remCalc(14)};

    img {
      height: ${remCalc(24)};
      width: ${remCalc(24)};
      margin-right: ${remCalc(8)};
    }
  `}
`

const Button = styled.a`
  height: ${remCalc(48)};
  width: ${remCalc(160)};
  text-transform: uppercase;
  background: ${color.sunshineyellow};
  color: ${color.black};
  text-align: center;
  border: 0;
  margin-top: ${remCalc(38)};
  border-radius: ${remCalc(4)};
  font-size: ${remCalc(16)};
  line-height: ${remCalc(48)};
  letter-spacing: ${remCalc(2.29)};
  font-weight: bold;
  display: block;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    opacity: 0.9;
  }

  ${respondTo.smAndDown`
    display: none;
  `}
`

const StyledLabel = styled(ArticleLevelLabel)`
  color: ${color.sunshineyellow};
`

const MostViewedCarouselItem = ({ entityUuid } = {}) => {
  const { pageType } = useGa4Params()
  const articles = useFetchArticles(entityUuid)
  const article = get(articles, `processedNode[${entityUuid}]`, {})

  const { headline = '', entityId = '', socialHeadline = '', authors, urlAlias, publishedDate } = article

  const author = get(authors, '[0]', {})
  const { name: authorName, urlAlias: authorUrl, image: authorImage } = author

  const authorImageUrl = get(authorImage, 'size_500x500.url') // TODO: handle default image

  const routeHandler = () => {
    // ga4 13.1.2
    setVariables({
      event: 'GA4_tracking',
      category: 'recirculation',
      subcategory: 'widget',
      action: 'click',
      customized_parameters: {
        widget_name: 'most_liked',
        article_id: entityId,
        destination_url: urlAlias,
        page_type: pageType,
      },
    })
  }

  return (
    <>
      <Wrapper>
        <Grid>
          <Row>
            <Col md={16} lg={14} xsOffset={0} smOffset={0} mdOffset={0} lgOffset={1}>
              <ContentWrapper>
                <StyledLabel entityUuid={entityUuid} />
                <Title>
                  <ArticleLink
                    article={pick(article, ['entityUuid', 'urlAlias'])}
                    routeHandler={routeHandler}
                  >
                    <a>{socialHeadline || headline}</a>
                  </ArticleLink>
                </Title>
                <StyledCustomDate date={publishedDate}></StyledCustomDate>
                {author && (
                  <AuthorFooter>
                    {authorImageUrl && <img src={authorImageUrl} />} by<> </>
                    {authorUrl ? <Link href={authorUrl}>{authorName}</Link> : <>{authorName}</>}
                  </AuthorFooter>
                )}
                <ArticleLink
                  article={pick(article, ['entityUuid', 'urlAlias'])}
                  routeHandler={routeHandler}
                >
                  <Button>Read</Button>
                </ArticleLink>
              </ContentWrapper>
            </Col>
          </Row>
        </Grid>
      </Wrapper>
    </>
  )
}

MostViewedCarouselItem.propTypes = {
  entityUuid: CustomPropTypes.uuid,
  initBackground: PropTypes.bool,
  showBackground: PropTypes.bool,
}

export default MostViewedCarouselItem
